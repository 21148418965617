export const ONE_DAY_MS = 1000 * 60 * 60 * 24;

export function getMonthsDifference(
  start: string | number | undefined,
  end: string | number | undefined,
) {
  if (!start || !end) {
    return 0;
  }

  const startDate = new Date(start);
  const endDate = new Date(end);

  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth();

  const endYear = endDate.getFullYear();
  const endMonth = endDate.getMonth();

  return (endYear - startYear) * 12 + (endMonth - startMonth);
}

export function getAverageDaysInMonths(
  startDate: string | number,
  endDate: string | number,
) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  let totalDays = 0;
  let numMonths = 0;

  while (start < end) {
    const year = start.getFullYear();
    const month = start.getMonth();

    const daysInMonth = new Date(year, month + 1, 0).getDate();

    totalDays += daysInMonth;
    numMonths++;

    start.setMonth(start.getMonth() + 1);
  }

  const averageDaysPerMonth = totalDays / numMonths || 0;

  return averageDaysPerMonth;
}
