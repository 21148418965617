import { ChooseBoxPrizeType } from '@app/components/ChooseTheBox/types';

export const generateLootData = (type: ChooseBoxPrizeType) => {
  let title = '',
    prizeTitle = '',
    description = '',
    image = '',
    modalDescription = '',
    revealImage = '';

  switch (type) {
    case ChooseBoxPrizeType.worm:
      title = 'Mystery Worm';
      prizeTitle = 'Mystery Worm';
      description = 'A mysterious companion will soon join you in the game';
      modalDescription =
        'At game launch, you can import the Mystery Worm into WormFare, revealing its unique skin. Each Mystery Worm features a different design, adding a personalized touch to your gaming experience.';
      image = '/media/assets/worm.png';
      revealImage = '/games/choose-box/prize-description/worm-reveal.png';
      break;
    case ChooseBoxPrizeType.card:
      title = 'Mystery Card';
      prizeTitle = 'Mystery Card';
      description =
        'This card holds unknown powers, soon to be revealed in your game';
      modalDescription =
        'At the release of the game, you can reveal the Mystery Card to discover its specific attributes and how it can be used in gameplay. Each card offers different strategies or advantages, enriching your tactical options in Wormfare.';
      image = '/media/assets/card.png';
      revealImage = '/games/choose-box/prize-description/card-reveal.png';
      break;
    case ChooseBoxPrizeType.accessory:
      title = 'Mystery Accessory';
      prizeTitle = 'Mystery Accessory';
      description =
        'Get ready for a unique addition to your game, soon to be unveiled';
      modalDescription =
        "At the game's launch, revealing the Mystery Accessory will unlock new powers for your worms. Each accessory is unique, giving your worms different enhancements or abilities, making your gameplay more dynamic and fun.";
      image = '/media/assets/accessory.png';
      revealImage = '/games/choose-box/prize-description/accessory-reveal.png';
      break;
    case ChooseBoxPrizeType.wofr:
      title = 'WOFR TOKENS';
      prizeTitle = '200 WOFR';
      description =
        'Added to your account, and will begin vesting after the TGE';
      image = '/media/assets/wofr.png';
      break;
  }

  return {
    title,
    prizeTitle,
    description,
    modalDescription,
    image,
    revealImage,
  };
};
