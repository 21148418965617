import { appConfig } from '@app/config';

export const getGoogleIds = async () => {
  const getClientId: Promise<string> = new Promise((resolve) => {
    window.gtag(
      'get',
      appConfig.googleAnalyticsTrackingId,
      'client_id',
      (res: string) => resolve(res),
    );
  });

  const getSessionId: Promise<string> = new Promise((resolve) => {
    window.gtag(
      'get',
      appConfig.googleAnalyticsTrackingId,
      'session_id',
      (res: string) => resolve(res),
    );
  });

  const client_id = await getClientId;
  const session_id = await getSessionId;

  return { client_id, session_id };
};
