export enum ChooseBoxGameState {
  init = 'game init',
  welcome = 'game welcome',
  started = 'game started',
  over = 'game over',
  dailyLimit = 'limit reached',
  gameEndedByDate = 'game ended: end date has come',
  gameEndedByRewards = 'game ended: no rewards left',
}

export enum ChooseBoxPrizeType {
  worm = 'worm',
  card = 'card',
  accessory = 'accessory',
  wofr = 'wofr',
  failed = 'game-over',
}

export enum ChooseBoxAudio {
  startGameClick = '/games/choose-box/audio/start-click.mp3',
  winGame = '/games/choose-box/audio/win-game.mp3',
  looseGame = '/games/choose-box/audio/loose-game.mp3',
  music = '/games/choose-box/audio/soundtrack.mp3',
  buttonClick = '/games/choose-box/audio/general-click.mp3',
}
