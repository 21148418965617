import { DiscountType, UserDiscount } from '@app/__generated__/graphql';

export const getUserDiscounts = (discounts: UserDiscount[] | undefined) => {
  let nft = 0;
  let referral = 0;
  let whitelist = 0;

  if (!discounts) {
    return {
      nft,
      referral,
      whitelist,
      total: 0,
    };
  }

  nft =
    discounts
      .filter((discount) => discount.type === DiscountType.GenesisNft)
      .reduce((acc, curr) => acc + curr.discount, 0) || 0;

  referral =
    discounts
      .filter((discount) => discount.type === DiscountType.ReferralCode)
      .reduce((acc, curr) => acc + curr.discount, 0) || 0;

  whitelist =
    discounts
      .filter((discount) => discount.type === DiscountType.Whitelist)
      .reduce((acc, curr) => acc + curr.discount, 0) || 0;

  return {
    nft,
    referral,
    whitelist,
    total: nft + referral + whitelist,
  };
};
