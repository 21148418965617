export const addNumberSuffix = (rank: number | string | undefined) => {
  if (rank === undefined) {
    return;
  }

  const lastDigit = +rank % 10;
  const lastTwoDigits = +rank % 100;

  if (lastDigit === 1 && lastTwoDigits !== 11) return 'st';

  if (lastDigit === 2 && lastTwoDigits !== 12) return 'nd';

  if (lastDigit === 3 && lastTwoDigits !== 13) return 'rd';

  return 'th';
};
