// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getConnectedWalletName = async (connector: any) => {
  if (!connector.name) {
    return 'unknown connector';
  }

  if (connector.name !== 'EIP6963') {
    return connector.name;
  }

  const provider = await connector.getProvider();

  return extractWordAfterIs(provider);
};

const extractWordAfterIs = (obj: Record<string, unknown>) => {
  for (const key in obj) {
    const match = key.match(/^is(.+)/);

    if (match) {
      return match[1];
    }
  }

  return null;
};
