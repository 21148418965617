export const formatHash = (hash: string | undefined) => {
  if (!hash) {
    return;
  }

  if (hash.length > 14) {
    return hash.slice(0, 5) + '...' + hash.slice(-4);
  }

  return hash;
};
