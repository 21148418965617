import { isClient } from '.';

export const getQueryParams = () => {
  if (!isClient()) {
    return null;
  }

  const currentURL = window?.location.href;
  const searchParams = new URLSearchParams(new URL(currentURL).search);

  return searchParams;
};
