export class AudioPlayer<T extends Record<string, string>> {
  private readonly audios: { [key in keyof T]: HTMLAudioElement };
  public isMuted: boolean;

  constructor(private readonly audioUrls: T) {
    this.audios = {} as { [key in keyof T]: HTMLAudioElement };
    this.isMuted = false;

    for (const key in this.audioUrls) {
      if (Object.prototype.hasOwnProperty.call(this.audioUrls, key)) {
        this.audios[key] = new Audio(this.audioUrls[key]);
      }
    }
  }

  static async loadAudio(url: string): Promise<HTMLAudioElement> {
    return new Promise((resolve, reject) => {
      const audio = new Audio(url);

      audio.addEventListener('canplaythrough', () => resolve(audio));
      audio.addEventListener('error', () =>
        reject(`Error loading audio: ${url}`),
      );

      return audio;
    });
  }

  play(name: keyof T, options?: { volume?: number; loop?: boolean }) {
    if (!this.audios[name]) {
      throw new Error(`Audio ${name.toString()} not found`);
    }

    this.audios[name].volume = options?.volume || 1;
    this.audios[name].loop = options?.loop || false;
    this.audios[name].play();
  }

  playAll(options = {}) {
    for (const key in this.audios) {
      this.play(key);
    }
  }
  stop(name: keyof T) {
    if (this.audios[name]) {
      this.audios[name].pause();
    } else {
      throw new Error(`Audio ${name.toString()} not found`);
    }
  }

  stopAll() {
    for (const key in this.audios) {
      this.stop(key as keyof T);
    }
  }

  muteUnmute() {
    this.isMuted = !this.isMuted;

    for (const key in this.audios) {
      if (this.audios[key]) {
        if (this.isMuted) {
          this.audios[key].muted = true;
        } else {
          this.audios[key].muted = false;
        }
      }
    }
  }
}
