import { GraphQLRequest } from '@apollo/client';
import { AuthRefreshTokensMutation, User } from '@app/__generated__/graphql';
import { isClient } from '@app/utils';
import { appConfig } from '@app/config';
import jwt_decode from 'jwt-decode';
import investorClient from '../investorClient';

export const AUTH_KEY_ACCESS_TOKEN = '__wormfare-dashboard-access-token__';
export const AUTH_KEY_REFRESH_TOKEN = '__wormfare-dashboard-refresh-token__';
export const CURRENT_USER_KEY_STORAGE = '__wormfare-dashboard-user__';
export const REFERRAL_CODE_KEY = '__wormfare-dashboard-referral-code__';
const API_KEY_NAME = 'X-API-KEY';
const GOOGLE_CLIENT_ID_KEY = '__wormfare_google_client_id';
const GOOGLE_SESSION_ID_KEY = '__wormfare_google_session_id';

export const getAccessTokenFromStorage = () => {
  return localStorage.getItem(AUTH_KEY_ACCESS_TOKEN);
};

export const getRefreshTokenFromStorage = () => {
  return localStorage.getItem(AUTH_KEY_REFRESH_TOKEN);
};

export const saveCredentialsToStorage = (
  accessToken: string | undefined,
  refreshToken: string | undefined,
) => {
  if (!accessToken || !refreshToken) {
    throw Error('Tokens not provided');
  }

  localStorage.setItem(AUTH_KEY_ACCESS_TOKEN, accessToken);
  localStorage.setItem(AUTH_KEY_REFRESH_TOKEN, refreshToken);
};

export const loadCredentialsFormStorage = () => {
  if (!isClient()) {
    return null;
  }

  const accessToken = getAccessTokenFromStorage();
  const refreshToken = getRefreshTokenFromStorage();

  if (!accessToken || !refreshToken) {
    return null;
  }

  try {
    return {
      accessToken: accessToken,
      refreshToken: refreshToken,
    };
  } catch (err) {
    console.error(err);

    return null;
  }
};

export const clearCredentials = () => {
  if (!isClient()) {
    return null;
  }

  localStorage.removeItem(AUTH_KEY_ACCESS_TOKEN);
  localStorage.removeItem(AUTH_KEY_REFRESH_TOKEN);
  localStorage.removeItem(CURRENT_USER_KEY_STORAGE);
};

export const loadCurrentUserFromStorage = () => {
  if (!isClient()) {
    return null;
  }

  const keyStorage = localStorage.getItem(CURRENT_USER_KEY_STORAGE);

  if (!keyStorage) {
    return null;
  }

  try {
    return JSON.parse(keyStorage) as User;
  } catch (err) {
    console.error(err);

    return null;
  }
};

export const saveCurrentUserToStorage = (user: User) => {
  if (user) {
    try {
      localStorage.setItem(CURRENT_USER_KEY_STORAGE, JSON.stringify(user));
    } catch (e) {
      console.error('Cant serialize');
    }
  }
};

export const returnApiKeyHeader = (operation: GraphQLRequest) => {
  const operationsWithApiKeyAccess = [
    'AuthLogin',
    'AuthRefreshTokens',
    'AuthMessage',
  ];
  const { operationName } = operation;

  if (!operationName) {
    return null;
  }

  if (operationsWithApiKeyAccess.includes(operationName)) {
    return { [API_KEY_NAME]: appConfig.api.investorApiKey };
  }

  return null;
};

export const getReferralCodeFromStorage = () => {
  if (!isClient()) {
    return null;
  }

  return sessionStorage.getItem(REFERRAL_CODE_KEY);
};

export const saveReferralCodeToStorage = (referralCode: string | undefined) => {
  if (!referralCode) {
    return;
  }

  sessionStorage.setItem(REFERRAL_CODE_KEY, referralCode);
};

export const clearReferralCodeFromStorage = () => {
  sessionStorage.removeItem(REFERRAL_CODE_KEY);
};

export const isTokenExpired = () => {
  const user = loadCurrentUserFromStorage();
  const ONE_MINUTE_MS = 60000;
  const REFRESH_EXPIRE_OFFSET_MINUTES = 10;
  const REFRESH_EXPIRE_OFFSET_MS =
    REFRESH_EXPIRE_OFFSET_MINUTES * ONE_MINUTE_MS;

  if (!user?.address) {
    return true;
  }

  const tokens = loadCredentialsFormStorage();
  const accessToken = tokens?.accessToken;
  const refreshToken = tokens?.refreshToken;

  if (!accessToken || !refreshToken) {
    return false;
  }

  const decodedAccessToken = jwt_decode(accessToken) as { exp: number };

  if (Date.now() >= decodedAccessToken.exp * 1000 - REFRESH_EXPIRE_OFFSET_MS) {
    console.log('Access token expired');

    return false;
  }

  return true;
};

export const getAccessToken = async () => {
  const refreshToken = getRefreshTokenFromStorage();

  const query = `
  mutation AuthRefreshTokens($refreshToken: String!) {
    auth {
      refreshTokens(refreshToken: $refreshToken) {
        accessToken
        refreshToken
      }
    }
  }`;

  const response = await fetch(appConfig.api.investorApiUrl, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      [API_KEY_NAME]: appConfig.api.investorApiKey,
    },
    body: JSON.stringify({
      operationName: 'AuthRefreshTokens',
      query,
      variables: {
        refreshToken,
      },
    }),
  });

  return response;
};

export const handleSaveToken = (res: string) => {
  try {
    const parsed = JSON.parse(
      res,
    ) as AuthRefreshTokensMutation['auth']['refreshTokens'];
    const accessToken = parsed?.accessToken;
    const refreshToken = parsed?.refreshToken;

    saveCredentialsToStorage(accessToken, refreshToken);
  } catch (e) {
    console.error(e);
    clearCredentials();
  }
};

export const handleRefreshResponse = async (response: Response) => {
  if (!response) {
    return { refreshTokens: null };
  }

  try {
    const resp = await response.json();
    const result = resp.data.auth.refreshTokens;

    return { refreshTokens: JSON.stringify(result) };
  } catch (e) {
    console.error(e);
    clearCredentials();
  }
};

export const getGoogleIdsFromStorage = () => {
  const client_id = localStorage.getItem(GOOGLE_CLIENT_ID_KEY);
  const session_id = localStorage.getItem(GOOGLE_SESSION_ID_KEY);

  return { client_id, session_id };
};

export const saveGoogleIdsToStorage = (clientId: string, sessionId: string) => {
  localStorage.setItem(GOOGLE_CLIENT_ID_KEY, clientId);
  localStorage.setItem(GOOGLE_SESSION_ID_KEY, sessionId);
};
