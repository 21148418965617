export enum ToFixedRounding {
  Normal = 'normal',
  Up = 'up',
  Down = 'down',
}

export const toFixed = function (
  number: number | string,
  n: number,
  rounding: ToFixedRounding = ToFixedRounding.Normal,
) {
  const k = Math.pow(10, n);
  let roundedNumber = +number;

  switch (rounding) {
    case ToFixedRounding.Normal:
      roundedNumber = Math.round(roundedNumber * k) / k;
      break;
    case ToFixedRounding.Up:
      roundedNumber = Math.ceil(roundedNumber * k) / k;
      break;
    case ToFixedRounding.Down:
      roundedNumber = Math.floor(roundedNumber * k) / k;
      break;
    default:
      roundedNumber = Math.round(roundedNumber * k) / k;
  }

  return roundedNumber.toString();
};
