import { appConfig } from '@app/config';

interface AnalyticsEvent {
  name: string;
  user_id: string | undefined;
  variables?: { [key: string]: string | number };
}

export const sendAnalyticsEvent = ({
  name,
  user_id,
  variables,
}: AnalyticsEvent) => {
  if (appConfig.env != 'production') {
    return;
  }

  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  window.dataLayer.push({
    event: name,
    user_id,
    ...variables,
  });

  console.log(name, user_id, variables);
};
